<template>
  <cks-combined-route>
    <cks-page-wrap v-loading="loading" style="padding-bottom: 100px;">
      <cks-edit-form
        title="走进穗深"
        @save="save"
        ref="formRef"
        label-position="top"
        :rules="rules"
        :model="form"
      >
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="荣誉资质(建议4~16张, 图片尺寸340px * 430px, 不应超过1M大小)" prop="honourPic">
              <cks-upload v-model="form.honourPic" @updateUrl="updateUrl" :fileUrl="form.honourPic" :limit="100"></cks-upload>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="员工风采(建议12~36张, 图片尺寸520px * 300px, 不应超过1M大小)" prop="staffDemeanorPic">
              <cks-upload v-model="form.staffDemeanorPic" @updateUrl="updateUrl2" :fileUrl="form.staffDemeanorPic" :limit="100"></cks-upload>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="合作伙伴(建议6~19张, 图片尺寸210px * 240px, 不应超过1M大小)" prop="partnersPic">
              <cks-upload v-model="form.partnersPic" @updateUrl="updateUrl3" :fileUrl="form.partnersPic" :limit="100"></cks-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </cks-edit-form>
    </cks-page-wrap>
  </cks-combined-route>
</template>

<script>
import {
  toRefs, reactive, onMounted,
} from 'vue';
import {
  showSuccess,
  // showError,
} from '@/utils';
import apis from '@/request/apis';

export default {
  setup() {
    const data = reactive({
      dialogImageUrl: '',
      dialogVisible: false,
      formRef: null,
      form: {
        partnersPic: '',
      },
      honourPicList: [],
      staffDemeanorPicList: [],
      partnersPicList: [],
      rules: {
        // honourPic: [getRequireRule('荣誉资质')],
        // staffDemeanorPic: [getRequireRule('员工风采')],
        // partnersPic: [getRequireRule('合作伙伴')],
      },
      loading: false,
    });

    async function save(done) {
      try { await data.formRef.validate(); } catch (e) { return done(false); }
      // if (data.form.partnersPic.split(',').length !== 0 && data.form.partnersPic.split(',').length > 19) {
      //   showError(new Error('合作伙伴最多上传19张图片'));
      //   done(false);
      //   return;
      // }
      try {
        await apis.enterSpikeDepth.save({
          ...data.form,
        });
        showSuccess('保存成功');
        done();
      } catch (e) {
        done(e);
      }
    }

    async function loadDetail() {
      data.loading = true;
      try {
        const res = await apis.enterSpikeDepth.getDetail();
        Object.assign(data.form, res);
      } finally {
        data.loading = false;
      }
    }

    const updateUrl = (val) => {
      data.form.honourPic = val;
    };
    const updateUrl2 = (val) => {
      data.form.staffDemeanorPic = val;
    };
    const updateUrl3 = (val) => {
      data.form.partnersPic = val;
    };

    onMounted(() => {
      loadDetail();
    });

    return {
      save,
      ...toRefs(data),
      updateUrl,
      updateUrl2,
      updateUrl3,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep .quill-editor {
  height: 400px;
}
::v-deep .ql-container{
  height: 300px;
}
.posi {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 20px;
  z-index: 999;
  background-color: #f5f7fa;
  opacity: 0.5;
  cursor:not-allowed;
}
.el-input {
  --el-input-focus-border: #2F51FF;
}
::v-deep.active-img-list .el-upload {
  display: none;
}
</style>
